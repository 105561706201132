/*Style Guide Content Measurements Begin */

$fontLarge: 138px;
$fontMedium: clamp(2rem, 1.4595rem + 2.7027vw, 4.5rem);
$fontMedium2: 42px;
$fontMedium3: 32px;
$fontSmall: 20px;
$fontForm: 26px;

$fontHeadingType: Raleway, Arial, Helvetica, sans-serif;
$fontBodyType: Raleway, Arial, Helvetica, sans-serif;

h2 {
  font-family: Beas Neue;
  text-transform: uppercase;
}
html {
  --headercolor: #0a0a0a;
  --sideMenucolor: #212121;
  --navIconBackgroundColor: black;
  --aboutColor: #4d4d4d;
  --waveColor: white;
  --navBackgroundColor: white;
  --skills: #212121;
  --skillsbefore: #000;
  --project: black;
  --projectfont: white;
  --contact: #2d3033;
  overflow-x: hidden;
  --skillIcon: black;
  --creditscolor: black;
}
html[data-theme="violet"] {
  --headercolor: rgb(60, 6, 69);
  --navBackgroundColor: white;
  --sideMenucolor: rgb(206, 162, 198);
  --navIconBackgroundColor: rgb(60, 6, 69);
  --waveColor: white;
  --aboutColor: rgb(73, 44, 70);
  --skillsbefore: rgb(44, 5, 49);
  --skills: rgb(206, 162, 198);
  --project: rgb(60, 6, 69);
  --projectfont: white;
  --contact: rgb(73, 44, 70);
  --skillIcon: rgb(60, 6, 69);
  --creditscolor: rgb(60, 6, 69);
}
html[data-theme="indigo"] {
  --headercolor: rgb(74, 43, 122);
  --navBackgroundColor: white;
  --sideMenucolor: rgb(148, 85, 244);
  --navIconBackgroundColor: rgb(74, 43, 122);
  --waveColor: white;
  --aboutColor: rgb(118, 68, 195);
  --skillsbefore: rgb(44, 5, 49);
  --skills: rgb(148, 85, 244);
  --project: rgb(74, 43, 122);
  --projectfont: white;
  --contact: rgb(73, 44, 70);
  --skillIcon: rgb(74, 43, 122);
  --creditscolor: rgb(60, 6, 69);
}
html[data-theme="blue"] {
  --headercolor: rgb(5, 5, 49);
  --navBackgroundColor: white;
  --sideMenucolor: #006cbe;
  --navIconBackgroundColor: rgb(5, 5, 49);
  --waveColor: white;
  --aboutColor: #2a9df4;
  --skillsbefore: rgb(5, 5, 49);
  --skills: #025ba0;
  --project: rgb(5, 5, 49);
  --projectfont: white;
  --contact: #025ba0;
  --skillIcon: rgb(5, 5, 49);
  --creditscolor: rgb(5, 5, 49);
}
html[data-theme="green"] {
  --headercolor: rgb(15, 39, 14);
  --navBackgroundColor: white;
  --navIconBackgroundColor: rgb(15, 39, 14);
  --sideMenucolor: rgb(15, 39, 14);
  --waveColor: white;
  --aboutColor: rgb(46, 116, 43);
  --skillsbefore: rgb(15, 39, 14);
  --skills: rgb(77, 194, 71);
  --project: rgb(15, 39, 14);
  --projectfont: white;
  --contact: rgb(62, 155, 57);
  --skillIcon: rgb(15, 39, 14);
  --creditscolor: rgb(15, 39, 14);
}
html[data-theme="orange"] {
  --headercolor: rgb(150, 63, 17);
  --navBackgroundColor: white;
  --sideMenucolor: orange;
  --navIconBackgroundColor: rgb(150, 63, 17);
  --waveColor: white;
  --aboutColor: rgb(250, 105, 29);
  --skillsbefore: rgb(150, 63, 17);
  --skills: rgb(200, 84, 23);
  --project: rgb(150, 63, 17);
  --projectfont: white;
  --contact: rgb(200, 84, 23);
  --creditscolor: rgb(150, 63, 17);
  --skillIcon: rgb(150, 63, 17);
}
html[data-theme="red"] {
  --headercolor: rgb(110, 5, 17);
  --navBackgroundColor: white;
  --sideMenucolor: rgb(95, 8, 8);
  --navIconBackgroundColor: rgb(110, 5, 17);
  --waveColor: white;
  --aboutColor: rgb(183, 9, 29);
  --skillsbefore: rgb(165, 8, 26);
  --skills: rgb(128, 6, 20);
  --project: rgb(110, 5, 17);
  --projectfont: white;
  --contact: rgb(165, 8, 26);
  --creditscolor: rgb(110, 5, 17);
  --skillIcon: rgb(165, 8, 26);
}
html[data-theme="rainbow"] {
  --headercolor: #360b54;
  --aboutColor: rgb(5, 5, 49);
  --skillsbefore: darkgreen;
  --skills: #ffff00;
  --project: rgb(150, 63, 17);
  --projectfont: white;
  --contact: #8b0000;
  --skillIcon1: #4b0082;
  --skillIcon2: rgb(5, 5, 49);
  --skillIcon3: darkgreen;
  --skillIcon4: #8b0000;
  --skillIcon5: #6d2a16;
}
.One {
  background-color: var(--skillIcon1);
}

.Two {
  background-color: var(--skillIcon2);
}

.Three {
  background-color: var(--skillIcon3);
}

.Four {
  background-color: var(--skillIcon4);
}

.Five {
  background-color: var(--skillIcon5);
}

@import "../scss/variables.scss";
@import "../App.scss";

.introLandingPage {
  width: 100vw;
  height: clamp(50vh, 21.3649rem + 38.3784vw, 100vh);
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--headercolor);
  position: relative;

  .titleContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    width: 100%;

    .boxContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h1 {
        text-transform: uppercase;
        font-family: $fontHeadingType;
        font-size: $fontMedium;
        display: flex;
        width: fit-content;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        color: white;
        padding-bottom: 30px;
        border-bottom: 6px solid orange;
        margin-bottom: 30px;
      }
  
      h2 {
        display: flex;
        color: white;
        font-size: clamp(1.5rem, 1.0676rem + 2.1622vw, 3.5rem);
        text-align: center;
        overflow: hidden;
        border-right: 0.15em solid orange;
        white-space: nowrap;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        animation: typing 3.5s steps(45, end), blink-caret 0.75s step-end infinite;
      }
    }

    
  }
  .bottomHeader {
    display: flex;
    position: absolute;
    justify-content: center;
    bottom: 200px;
    left: 50%;

    .scrolldownArrow {
      display: flex;
      position: absolute;
      justify-content: center;
      animation: MoveUpDown 3s linear infinite;
      font-size: clamp(3rem, 2.027rem + 4.8649vw, 7.5rem);
      color: white;
    }

    .scrolldownArrow:hover {
      color: goldenrod;
      cursor: pointer;
    }
  }
  @keyframes MoveUpDown {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }

  .wrapper {
    height: 100%;
    width: 100%;

    position: absolute;
  }
  .wrapper div {
    height: 60px;
    width: 60px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: 4s linear infinite;
  }
  div .dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
    z-index: 999;
  }
  .wrapper div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(2) {
    top: 60%;
    left: 80%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(3) {
    top: 40%;
    left: 40%;
    animation: animate 3s linear infinite;
  }
  .wrapper div:nth-child(4) {
    top: 66%;
    left: 30%;
    animation: animate 7s linear infinite;
  }
  .wrapper div:nth-child(5) {
    top: 90%;
    left: 10%;
    animation: animate 9s linear infinite;
  }
  .wrapper div:nth-child(6) {
    top: 30%;
    left: 60%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(8) {
    top: 75%;
    left: 60%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(9) {
    top: 50%;
    left: 50%;
    animation: animate 6s linear infinite;
  }
  .wrapper div:nth-child(10) {
    top: 45%;
    left: 20%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(11) {
    top: 10%;
    left: 90%;
    animation: animate 9s linear infinite;
  }
  .wrapper div:nth-child(12) {
    top: 20%;
    left: 70%;
    animation: animate 7s linear infinite;
  }
  .wrapper div:nth-child(13) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(14) {
    top: 60%;
    left: 5%;
    animation: animate 6s linear infinite;
  }
  .wrapper div:nth-child(15) {
    top: 90%;
    left: 80%;
    animation: animate 9s linear infinite;
  }
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(70deg);
    }
    100% {
      transform: scale(1.3) translateY(-100px) rotate(360deg);
    }
  }
}
@media only screen and (max-width: 800px) {
  .introLandingPage {
    .titleContainer {
      h2 {
        animation: none;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        border: none;
        line-height: 35px;
      }
    }
  }
}

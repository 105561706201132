@import "./styleGuide";

.skills {
  background-color: var(--skills);
  position: relative;
}
.skills:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: clamp(11rem, 0.2703rem + 43.2432vw, 50rem);
  background: var(--skillsbefore);
  transform: skew(-30deg);
  transform-origin: top;
  z-index: 100;
}

.skillsWrapper {
  padding: 5%;
}
.skills-icons {
  z-index: 888;
  border-radius: 25px;
  padding: 50px;

}
.skillsDev {
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 100px;
  z-index: 110;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    color: white;
    z-index: 899;
    font-family: Raleway;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    li {
      min-width: 200px;
      max-width: 200px;
      height: 200px;
      background-color: var(--skillIcon);
      border-radius: 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 1%;
      outline: solid silver 5px;
      color: white;
      z-index: 899;
      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    }

    li:hover {
      color: goldenrod;
      cursor: pointer;
      transform: scale(1.03);
      transition: all 0.3s ease;
      border: solid goldenrod 5px;
      transform: translateY(-5px);
      transition: all 0.15s ease-in-out;
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
      font-family: $fontBodyType;
      word-wrap: break-word;
    }

    i {
      font-size: 48px;
    }
    .fontAwesomeSkillIcon {
      font-size: 48px;
    }
  }
}

.wrap:nth-child(1) {
  animation: animate1 0.25s linear 1;
}
.wrap:nth-child(2) {
  animation: animate1 0.5s linear 1;

}
.wrap:nth-child(3) {
  animation: animate1 0.75s linear 1;

}
.wrap:nth-child(4) {
  animation: animate1 1s linear 1;

}
.wrap:nth-child(5) {
  animation: animate1 1.25s linear 1;

}
.wrap:nth-child(6) {
  animation: animate1 1.5s linear 1;

}
.wrap:nth-child(7) {
  animation: animate1 1.75s linear 1;
}
.wrap:nth-child(8) {
  animation: animate1 2s linear 1;
}
.wrap:nth-child(9) {
  animation: animate1 2.25s linear 1;
}
.wrap:nth-child(10) {
  animation: animate1 2.5s linear 1;
}
.wrap:nth-child(11) {
  animation: animate1 2.75s linear 1;
}
.wrap:nth-child(12) {
  animation: animate1 3s linear 1;
}
.wrap:nth-child(13) {
  animation: animate1 3.25s linear 1;
}
.wrap:nth-child(14) {
  animation: animate1 3.5s linear 1;
}
.wrap:nth-child(15) {
  animation: animate1 3.75s linear 1;
}
.wrap:nth-child(16) {
  animation: animate1 4s linear 1;
}
@keyframes animate1 {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 1325px) {
  .skills-icons {
    background-color: transparent;
    z-index: 888;
    border-radius: none;
    padding: 0;
    border: none;
  }
  .icon2 {
    display: none;
  }
  .skillsDev {
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 50px;
    z-index: 110;

    h2 {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      color: ghostwhite;
      -webkit-text-stroke-width: none;
      -webkit-text-stroke-color: transparent;
      stroke: transparent;
      z-index: 899;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      li {
        min-width: 150px;
        max-width: 150px;
        height: 150px;
        background-color: none;
        border-radius: none;
        outline: solid silver 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 1%;
        color: white;
        z-index: 899;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      }

      .icon2 {
        display: none;
      }
      p {
        font-size: 16px;
        text-transform: uppercase;
        font-family: $fontBodyType;
        word-wrap: break-word;
      }
      li:hover {
        color: goldenrod;
        cursor: pointer;
        transform: scale(1.03);
        transition: all 0.3s ease;
        border: solid goldenrod 5px;
        transform: translateY(-5px);
        transition: all 0.15s ease-in-out;
      }

      i {
        font-size: 38px;
      }
      .fontAwesomeSkillIcon {
        font-size: 38px;
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .skillsDev {
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
    z-index: 110;

    ul {
      li {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        margin: 15px;
      }

      p {
        font-size: 16px;
      }
      li:hover {
        border: solid goldenrod 2px;
        transform: translateY(-5px);
        transition: all 0.15s ease-in-out;
      }

      i {
        font-size: 28px;
      }
      .fontAwesomeSkillIcon {
        font-size: 28px;
      }
    }
  }
  .introLandingPage .bottomHeader {
    bottom: 100px;
  }
}

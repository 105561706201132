.closed {
  display: none;
}
.opened {
  display: flex;
}
.mainMenu,
.themeMenu,
.musicMenu {
  flex-direction: column;
}
.smoothShown {
  -webkit-transform: translateX(100%);
  transform: translateX(-100%);
  transition: transform 300ms linear;
}

.smoothClosed {
  -webkit-transform: none;
  transform: none;
  transition: transform 300ms linear;
}
.mainMenu,
.themeMenu,
.musicMenu {
  flex-direction: column;
}
.fontAweIconSideMenu {
  margin-right: 10px;
}
.sideMenu {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 25vw;
  max-width: 300px;
  min-width: 250px;
  align-items: center;
  height: 100%;
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 999;
  background-color: var(--sideMenucolor);
  border-right: solid grey 1px;
  border-top: solid grey 1px;

  ul {
    width: 100%;
  }
  button {
    align-items: center;
    border: none;
    height: 60px;
    justify-content: center;
    text-align: center;
    width: 100%;
    outline: solid black 2px;
  }
  button:hover {
    background-color: slategray;
    cursor: pointer;
    color: white;
  }
.iconsBottom {
  margin-left: 10px;
}
}
.currentSong {
  border: solid black 3px;
}
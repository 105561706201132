@import "../../App.scss";

.NavBar {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5%;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  overflow: hidden;
  border-bottom: solid 1px grey;
  .navBarUpperSite {
    display: flex;
    flex-direction: row;
    width: clamp(50rem, 37.5rem + 20vw, 60.5rem);
    justify-content: space-between;
    list-style-type: none;
    height: 60px;
    align-items: center;

    li {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
    }
    a {
      width: clamp(8rem, 4.5385rem + 6.9231vw, 12.5rem);
      height: 40px;
      position: relative;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      background-color: var(--navIconBackgroundColor);
      cursor: pointer;
    }
    li {
      ul {
        position: fixed;
        flex-direction: column;
        top: 60px;
        background-color: #f5f5f5;
        width: clamp(8rem, 4.5385rem + 6.9231vw, 12.5rem);
        border-top: solid grey 1px;
        li {
          height: 60px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #f5f5f5;
        }
        li:hover {
          background-color: goldenrod;
          color: white;
        }
      }
    }

    a:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      transition: all 0.4s ease;
    }
    a:before {
      content: "";
      position: absolute;
      right: -20px;
      margin-left: 20px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid var(--navIconBackgroundColor);
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: none;
    }

    a:hover::before {
      border-left: 20px solid darkgray;
      transition: all 0.4s ease;
    }

    a:hover {
      background-color: darkgray;
      color: black;
      transition: all 0.4s ease;
    }
    a:hover a {
      color: black;
    }
  }
  a {
    text-decoration: none;
    font-family: Raleway;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.NavClosed {
  display: none;
}

.NavOpened {
  display: flex;
}
.rotating {
  transition: transform 300ms;
  transform: rotate(360deg);
}
.NavMobile {
  border-radius: 90%;
  width: 40px;
  height: 40px;
  border: solid goldenrod 2px;

  svg {
    transition: transform 0.5s ease-in 0.3s;
  transform: rotate(360deg);
  }
  cursor: pointer;
}
.white {
  background-color: white;
}
.menuItem {
  padding-right: 10px;
}
.black {
  background-color: var(--navBackgroundColor);
}
.notActive {
  color: gold;
}
.active {
  color: white;
}
@media only screen and (max-width: 925px) {
  .NavBar .navBarUpperSite {
    display: none;
  }
  .NavBar {
    height: 60px;
    padding: 0% 5%;
  }
}

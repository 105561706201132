@import "./styleGuide.scss";

.projectWrapper {
  width: 100%;
  height: 100%;
  padding: 5% 15%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  border: solid goldenrod 5px;
  border-left: none;
  border-right: none;
  background-color: var(--project);
  color: var(--projectfont);
}

.projectLists {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
  padding-top: 4%;
  margin: 40px;
  border: solid goldenrod 5px;
  border-left: none;
  border-right: none;

  h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-family: $fontBodyType;
    letter-spacing: 2px;
    border-bottom: goldenrod solid 2px;
    width: fit-content;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 20px;
    font-family: $fontBodyType;
    letter-spacing: 2px;
  }
  a {
    color: orange;
    text-decoration: none;
  }
  a:link {
    color: orange;
  }
  a:visited {
    color: rgb(199, 35, 199);
  }
  a:hover {
    color: green;
  }
  p {
    font-size: 20px;
    font-family: $fontBodyType;
    word-wrap: break-word;
    line-height: 29px;
    width: 100%;
  }

  .char1 {
    color: goldenrod;
  }

  h5 {
    font-size: 20px;
    font-family: $fontBodyType;
  }
}
.project1 {
 
  .projectsItemWrapper {
    flex-direction: row-reverse;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 100px 0;
    padding-bottom: 30px;
    border-bottom: grey solid 1px;
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    transition: transform 1200ms linear;
    
  }

  .animationActiveProject {
    animation: fadein 6s 1;
    -webkit-transform: none;
    transform: none;
    transition: transform 1200ms linear;
  }

  .projectPara {
    margin-left: 100px;
  }
  img {
    width: 400px;
    height: 267px;
    box-shadow: -12px 10px 5px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: -12px 10px 5px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: -12px 10px 5px 0px rgba(255, 255, 255, 0.75);
  }
}

@keyframes slideinLeft {
  0% {
    margin-left: -6800px;
  }
  20% {
    margin-left: -6800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.project2 {
  .projectsItemWrapper {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: grey solid 1px;
    position: relative;
    -webkit-transform: translateX(150%);
    transform: translateX(150%);
    transition: transform 1200ms linear;
    
  }
  .animationActiveProject {
    animation: fadein 6s 1;
    -webkit-transform: none;
    transform: none;
    transition: transform 1200ms linear;
  }
  .projectPara {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 100px;
    order: 1;
  }

  p {
    text-align: left;
  }
  .imgProjectContainer {
    order: 2;
    cursor: pointer;
    img {
      width: 400px;
      height: 267px;
      display: flex;
      box-shadow: 10px 10px 5px 0px rgba(255, 255, 255, 0.75);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(255, 255, 255, 0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(255, 255, 255, 0.75);
    }
  }
}
.imgProjectContainer:hover img {
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

@keyframes shadowBox {
  0% {
  }
  100% {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}

.imgProjectContainer:hover .projectOverView {
  background-color: black;
  opacity: 0.7;
  display: flex;
  z-index: 10;
}
.projectShortParagraph {
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projectOverView {
  position: absolute;
  display: none;
  width: 400px;
  cursor: pointer;
  height: 267px;
}
.imgProjectContainerWrapper {
  position: absolute;
  display: none;
  width: 400px;
  cursor: pointer;
  height: 267px;
}
.imgProjectContainer:hover .imgProjectContainerWrapper {
  display: flex;
}
.projectShortParagraph {
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  justify-content: center;
  align-items: center;
  h3 {
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    width: 400px;
    padding: 10px;
    text-align: center;
    z-index: 12;
    border: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
    justify-self: center;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  h3:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    border-bottom: 1px solid goldenrod;
  }
  p {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    word-wrap: break-word;
    width: 400px;
    padding: 10px;
    text-align: center;
    z-index: 12;
    border: none;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .iconEye {
    color: goldenrod;
    font-size: 18px;
    z-index: 12;
  }
}
@keyframes slideinRight {
  0% {
    margin-right: -3800px;
  }
  20% {
    margin-right: -3800px;
  }
  35% {
    margin-right: 0px;
  }
  100% {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1325px) {
  .project2 .projectsItemWrapper,
  .project1 .projectsItemWrapper {
    flex-direction: column;
    margin: 0px;

    .projectPara {
      margin-left: 0;
      margin-top: 30px;

      p {
        width: 100%;
      }
    }
  }
  p {
    width: 100%;
  }
  .project2 {
    justify-content: left;
    width: 100%;
    margin-right: 0;

    p {
      text-align: left;
    }
    .projectPara {
      margin-right: 0;
      align-items: normal;
      order: 2;
    }

    img {
      order: 1;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 725px) {
  .projectWrapper {
    padding: 5% 5%;
  }
}

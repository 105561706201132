@import "../../scss/_variables.scss";

.about {
  width: 100%;
  height: 100%;
  background-color: var(--aboutColor);
  border: solid goldenrod 5px;
  border-left: none;
  border-right: none;
}

.aboutParagraph {
  .boxTitle {
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    transition: transform 4900ms linear;
  }

  .paragrapgh {
    -webkit-transform: translateX(150%);
    transform: translateX(150%);
    transition: transform 4900ms linear;
  }

  
.aboutActiveAnimation {
  animation: fadein 6s;
  -webkit-transform: none;
  transform: none;
  transition: transform 1200ms linear;
  color: white;
}
}
.aboutWrapper {
  padding: 0 clamp(2rem, 1.1351rem + 4.3243vw, 6rem);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewFix {
  outline: solid 5px white;
  height: 100%;
  padding: 10% 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.aboutParagraph {
  text-align: center;
  font-size: clamp(1rem, 0.8919rem + 0.5405vw, 1.5rem);
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;

  button {
    display: none;
    cursor: pointer;
  }
  button:hover {
    background-color: gold;
  }
  //jjjkk//
  a {
    color: rgb(255, 183, 0);
    text-decoration: none;
    border: solid rgb(255, 183, 0) 5px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.77);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.77);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.77);
    transition: all 0.4s ease;
  }

  a:link {
    color: rgb(255, 183, 0);
  }
  a:hover {
    color: green;
    background-color: rgb(255, 183, 0);
  }

  a:visited {
    color: lightblue;
  }

  .boxTitle {
    min-width: clamp(19rem, 16.9459rem + 10.2703vw, 28.5rem);
    max-width: clamp(19rem, 16.9459rem + 10.2703vw, 28.5rem);
    max-height: clamp(19rem, 16.9459rem + 10.2703vw, 28.5rem);
    min-height: clamp(19rem, 16.9459rem + 10.2703vw, 28.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid goldenrod 40px;
    background-color: white;
    box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
    -webkit-box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
    -moz-box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
    img {
      height: 90%;
      width: 90%;
    }
  }
  p {
    line-height: 30px;
    margin-left: 70px;
    text-align: left;
    letter-spacing: 1px;
    font-family: $fontBodyType;
    margin-bottom: 50px;
  }
}
h2 {
  font-size: clamp(1.5rem, 1.0676rem + 2.1622vw, 3.5rem);
}
.paragrapghReadMore {
  display: none;
}
.showPara {
  display: none;
}
@media only screen and (max-width: 1325px) {
  .aboutParagraph {
    flex-direction: column;
    align-items: center;
  }
  .boxTitle {
    margin-bottom: 50px;
  }
  .aboutParagraph p {
    margin: 0;
    padding: 30px 70px;
  }
  .aboutWrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 800px) {
  .aboutParagraph p {
    margin: 0;
    padding: 30px;
  }
  .secondParagraphAbout {
    display: none;
  }
  .aboutParagraph button {
    display: flex;
    margin-bottom: 50px;
    background-color: white;
    font-family: Raleway;
    padding: 15px;
    border-radius: 25px;
  }
  .paragrapgh {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}
@media only screen and (max-width: 925px) {
  .sideMenu {
    top: 61px;
    border-top: solid grey 1px;
    z-index: 10000;
  }
  .paragrapghReadMore {
    display: flex;
  }
}

@import "../../../scss/_variables.scss";
@import "../../../sass/styleGuide.scss";

.bottomSite-Wrapper {
  padding: 3% 5%;
  padding-bottom: 0;
  @include FlexColumn;
}

.bottomSite {
  background-color: var(--contact);
  color: white;

  h2 {
    font-size: clamp(1.5rem, 1.0676rem + 2.1622vw, 3.5rem);
    font-family: Raleway;
    margin-bottom: 50px;
    text-shadow: 5px 4px 0px black;
  }
  h3 {
    font-size: clamp(1rem, 0.625rem + 1.6667vw, 2.5rem);
    font-family: Raleway;
    padding-bottom: 50px;
    text-shadow: 5px 4px 0px black;
  }
  p {
    margin: 0;
  }

  ul {
    width: 100%;
    @include FlexRow;
    align-items: center;
    justify-content: space-evenly;
    @include UlNormalize;
    margin-bottom: 70px;
    a {
      text-decoration: none;
      padding: 0;
      text-transform: uppercase;
      color: white;
      font-family: Raleway;
      font-weight: 500;
      font-size: 24px;
    }
  }
}

.bottomSite ul li:hover a {
  color: gold;
}

.bottomSiteWebsiteTitleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.credits {
  width: 100%;
  display: flex;
  background-color: var(--creditscolor);
  justify-content: center;
  padding: 25px;
  align-items: center;
  border-top: solid gold 5px;
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: 1px;
}

.iconlListWrapper {
  color: white;
  font-size: 38px;
  display: flex;
  justify-content: space-evenly;
  padding: 5%;
  width: 100%;
}
.iconlListWrapper .iconsBottom {
  color: white;
  font-size: 58px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  box-shadow: -8px 8px 5px 0px rgba(0, 0, 0, 0.77);
  -webkit-box-shadow: -8px 8px 5px 0px rgba(0, 0, 0, 0.77);
  -moz-box-shadow: -8px 8px 5px 0px rgba(0, 0, 0, 0.77);
}
.iconlListWrapper .iconsBottom:hover {
  color: gold;
  cursor: pointer;
}

input,
label,
textarea {
  display: block;
}
.promptForm {
  width: 400px;
  height: 200px;
  border-radius: 15px;
  background-color: white;
  color: black;
  font-weight: 600;
  position: fixed;
  top: 30%;
  left: 50%;
  border: solid Gold 4px;
  flex-direction: column;
  margin-top: -150px;
  margin-left: -200px;

  p {
    font-size: 18px;
    line-height: 29px;
    text-transform: capitalize;
    font-family: Raleway;
  }
}

.promptFormWrapper {
  @include FlexColumn;
  @include Maxsize100;
  text-align: center;
  position: relative;
  justify-content: center;
  padding: 10px;

  button {
    position: absolute;
    top: -1px;
    left: 0;
    background-color: var(--creditscolor);
    color: white;
    border-top-left-radius: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.promptFormWrapper button:hover {
  background-color: white;
  color: black;
  font-size: 18px;
}

.shown {
  display: flex;
}

.hidden {
  display: none;
}

.bottomSite input,
.bottomSite select,
.bottomSite textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
  -webkit-box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
  -moz-box-shadow: 14px 10px 5px 0px rgba(0, 0, 0, 0.77);
}

.bottomSite textarea {
  height: 200px;
}
.bottomSite input[type="submit"] {
  background-color: goldenrod;
  color: black;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.bottomSite input[type="submit"]:hover {
  background-color: green;
}

.bottomSite .container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
.bottomSite .formContainer {
  width: 50%;
  padding-left: 5%;
  order: 2;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    @include FlexColumn;
    justify-content: center;
    align-items: center;
  }
}
.contactMethods {
  width: 50%;
  order: 1;
  padding: 0 clamp(0rem, -0.8649rem + 4.3243vw, 4rem);
  border-right: goldenrod solid 4px;
  p {
    @include SmallHeading;
    color: white;
    line-height: 29px;
  }
}

.contactWrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}

@media only screen and (max-width: 825px) {
  .contactWrapper {
    flex-direction: column;
  }
  .contactMethods,
  .formContainer {
    width: 100%;
    border: none;
    margin-top: 2%;
  }
  .bottomSite .formContainer {
    width: 100%;
    margin-top: 50px;
    padding: 0 clamp(0rem, -0.8649rem + 4.3243vw, 4rem);
  }
  .credits {
    text-align: center;
    line-height: 29px;
  }
  .bottomSite {
    text-align: center;
    h2 {
      font-size: clamp(2.5rem, 2.125rem + 1.6667vw, 3rem);
      padding: 40px 0;
      margin-bottom: 0;
    }
    h3 {
      font-size: clamp(2rem, 1.625rem + 1.6667vw, 2.5rem);
    }
    p {
      line-height: 135%;
    }
  }
  .iconList {
    padding-bottom: 40px;
    i {
      color: gold;
    }
  }
  .iconList i:hover {
    color: white;
  }
}
